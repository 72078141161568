@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'Fonts';
@import url('https://api.mapbox.com/mapbox-gl-js/v2.8.1/mapbox-gl.css');
@import "node_modules/plyr/src/sass/plyr.scss";
@import "node_modules/tiny-slider/src/tiny-slider.scss";
@import 'Plyr';
@import 'chocolat.css';

@layer utilities {

    // https://kulturbanause.de/blog/flexbox-responsive-layouts-ohne-media-queries/
    .flex-item-distribute-responsive {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: calc(960px * 999 - 100% * 999);
    }
}

[x-cloak] {
    display: none !important;
}


html {
    @apply scroll-pt-12
}


// MAPBOX
.mapboxgl-marker {
    background-image: url('/_Resources/Static/Packages/MI.Site/Icons/karte-anker.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 40px;
    height: 40px;
    cursor: pointer;

    svg {
        display: none;
    }
}

.mapboxgl-popup-tip {
    @apply hidden;
}

.mapboxgl-popup {
    max-width: 320px !important;
}

.mapboxgl-popup-content {
    background: none !important;
    padding: 5px;
    box-shadow: none;

    a {
        -webkit-tap-highlight-color: transparent !important;
        outline: none !important;
        box-shadow: none !important;
        border: none !important;
    }
}

.mapboxgl-popup-close-button {
    @apply hidden;
}

.slider-container {
    &.ratio-16-9 {
        .slider-slide {
            @apply aspect-[16/9];
        }
    }

    &.ratio-4-3 {
        .slider-slide {
            @apply aspect-[4/3];
        }
    }
}


// Slider
.tns-nav {
    @apply absolute bottom-[92px] left-0 w-full flex items-center justify-center gap-2 z-20
}

.tns-nav button {
    @apply bg-white w-2 h-2 block
}

.tns-nav button.tns-nav-active {
    @apply bg-brand-green w-3.5 h-3.5
}

.tns-horizontal.tns-subpixel {
    white-space: normal;
}

[data-pin-href] {
    left: 0 !important;
}