/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../Fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../Fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../Fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../Fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../Fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../Fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('../Fonts/montserrat-v25-latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../Fonts/montserrat-v25-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../Fonts/montserrat-v25-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
    url('../Fonts/montserrat-v25-latin-800.woff') format('woff'), /* Modern Browsers */
    url('../Fonts/montserrat-v25-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../Fonts/montserrat-v25-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}
